<template>
  <b-card title="">
    <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Ativas"
          @click="(workspaceList = false), getAllWorkspaces()"
          active
        >
        </b-tab>
        <b-tab
          title="Inativas"
          @click="(workspaceList = true), getInactivesWorkspaces()"
        >
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>

      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-button
            class="mb-4 mr-2"
            variant="primary"
            @click="$router.push({ name: 'new-workspace' })"
          >
            Nova área de trabalho +
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :items="filter()"
      :fields="fields"
      :per-page="30"
      :current-page="0"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsWorkspaces :item="item" />
      </template>
    </b-table>
    <b-pagination
      class="justify-content-center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="getPage"
      aria-controls="my-table"
    ></b-pagination>
    <div class="mr-2 d-flex justify-content-end">
      <strong>Total de áreas de trabalho: {{ totalRows }} </strong>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BPagination,
} from 'bootstrap-vue';
import ActionsWorkspaces from './components/ActionsWorkspaces';
import WorkspaceMixin from './ListWorkspaces.mixin';

export default {
  mixins: [WorkspaceMixin],
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BButton,
    BRow,
    ActionsWorkspaces,
    BCol,
    BFormInput,
    BFormGroup,
    BPagination,
  },
  data() {
    return {
      search: '',
    };
  },
};
</script>

<style></style>
